import { flatten } from "lottie-colorify";
import React, { useEffect, useRef } from "react";

import { useLottieWeb } from "gyg_common/hooks";

import config from "../../../config";
import resetButtonAnimation from "../../assets/gif/resetButton.json";
import colours from "../../styles/colours";
import styles from "./Spinner.styles";
// web spinner loader
interface SpinnerProps {
  containerStyle?: React.CSSProperties;
  color?: string;
  autoPlay?: boolean;
}

const Spinner: React.FC<SpinnerProps> = ({
  containerStyle = {},
  color,
  autoPlay = true,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { lottie } = useLottieWeb();

  useEffect(() => {
    if (!lottie) {
      return;
    }

    lottie.destroy();
    lottie.loadAnimation({
      container: wrapperRef.current,
      renderer: "canvas",
      loop: true,
      autoplay: autoPlay ? autoPlay : !config.visualTestingMode,
      animationData: flatten(color ?? colours.yellow, resetButtonAnimation),
    });
    if (autoPlay == false) {
      lottie?.pause();
    }
  }, [color, autoPlay, lottie]);

  return (
    <div
      style={{ ...styles.webLoader, ...containerStyle }}
      ref={wrapperRef}
      data-testid='spinner-loader'
      id='spinner-loader'
    />
  );
};

export default Spinner;
